<template>
    <div class="component-container">
        <div class="linkedin-block">
            <iframe :src="cmsBlock.embedUrl" height="691" width="500" frameborder="0" allowfullscreen="" title="Eingebetteter Beitrag"></iframe> 
        </div>
    </div>
</template>

<script>
export default {
    name: 'LinkedIn',

    props: [
        'cmsBlock'
    ],
}
</script>

<style lang="scss" scoped>
.component-container {

    .linkedin-block {
        max-width: 500px;
        margin: 0 auto;
    }

}


</style>
